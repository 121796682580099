import React, { useEffect } from 'react'
import banner_faq_2 from '../assets/img_y/banner_faq_2.png'
import { Link } from 'react-router-dom'

export default function PlayAndEarn() {
    useEffect(() => {
        document.body.classList.remove(...document.body.classList)
        document.body.classList.add('body__bg')
        document.body.classList.add('faq-page')
    }, [])

    return (
        <div className="gaming_video_section">
            <section className="gaming_update_section mb-222">
                <div className="container">
                    <div className="section_title text-center wow fadeInUp mb-40" style={{ marginTop: '150px' }} data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <h2 className="text_1 fs_80 shadow_1"><span style={{ fontSize: '60px', color: '#fff' }}>PLAY & EARN</span></h2>
                    </div>
                    <div className="gaming_video_thumb upcoming_gaming_list_2 o-90 bg-8 wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1.1s" style={{ backgroundImage: 'url(' + banner_faq_2 + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'right top', backgroundSize: 'cover', visibility: 'visible', animationDuration: '1.1s', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text_5 fs_60  lh-40  shadow_4 faq_question_list22 " style={{ marginTop: '200px', marginBottom: '200px' }}>
                                    <div className="section_title text-center wow fadeInUp mb-80 display-2" data-wow-delay="0.1s" data-wow-duration="1.1s" style={{ visibility: 'visible', animationDuration: '1.1s', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                                        Mainnet Coming Soon<br />           
                                        <a className="btn btn-link shadow_1" style={{ fontSize: '35px', fontWeight: '100', margin: '0 auto' }} href="https://testnet.oceanland.io/">
                                            Join Testnet
                                        </a>                                                                 
                                    </div>                                                                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

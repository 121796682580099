import React from 'react'
import partners_1 from '../assets/img_y/partners/p_ankr.png'
import partners_2 from '../assets/img_y/partners/p_arpa.png'
import partners_3 from '../assets/img_y/partners/p_auction.png'
import partners_4 from '../assets/img_y/partners/p_bel.png'
import partners_5 from '../assets/img_y/partners/p_BURGER.png'
import partners_6 from '../assets/img_y/partners/p_CLV.png'
import partners_7 from '../assets/img_y/partners/p_MATTER.png'
import partners_8 from '../assets/img_y/partners/p_ONX.png'
import partners_9 from '../assets/img_y/partners/p_PROM.png'
import partners_10 from '../assets/img_y/partners/p_ROCKI.png'
import partners_11 from '../assets/img_y/partners/p_SAKURA.png'
import partners_12 from '../assets/img_y/partners/p_NAV.png'
import partners_13 from '../assets/img_y/partners/p_LIT.png'
import partners_14 from '../assets/img_y/partners/p_gafi.png'
import partners_15 from '../assets/img_y/partners/POLKA.png'
import partners_16 from '../assets/img_y/partners/p_TFBX.png'
import partners_17 from '../assets/img_y/partners/guildturk.png'
import partners_18 from '../assets/img_y/partners/iToken.png'

function Partners() {
    return (
        <section id="partners-section" className="upcoming_gaming_section mb-222">
            <div className="container">
                <div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                    <h2 className="text_1 fs_95 shadow_1">Partners</h2>
                </div>
                <div className="row wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1.1s">
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_16} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_1} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_2} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_3} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_4} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_5} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_6} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_7} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_8} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_9} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_10} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_11} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_12} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_13} alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_14} alt="" />
                        </div>
                    </div> 
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_15} alt="" />
                        </div>
                    </div> 
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_17} alt="" />
                        </div>
                    </div>  
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-8">
                            <img src={partners_18} alt="" />
                        </div>
                    </div>                                                             
                </div>
            </div>
        </section>
    )
}

export default Partners

import React, { Fragment } from 'react'
import faz_1 from '../assets/img_y/faz_1.png'
import faz_2 from '../assets/img_y/faz_2.png'
import faz_3 from '../assets/img_y/faz_3.png'
import faz_4 from '../assets/img_y/faz_4.png'
import ferman_1 from '../assets/img_y/ferman_1.png'
import gemi from '../assets/img_y/roadmap/Gemi.png'
import stone_1 from '../assets/img_y/roadmap/stone_q1-q2_23.png'
import stone_2 from '../assets/img_y/roadmap/stone_q3_23.png'
import stone_3 from '../assets/img_y/roadmap/stone_q4_23.png'
import stone_4 from '../assets/img_y/roadmap/stone_q1_24.png'
import stone_5 from '../assets/img_y/roadmap/stone_q2_24.png'
import parchment from '../assets/img_y/roadmap/parchment.png'
import FICI from '../assets/img_y/roadmap/FICI.png'
import Karakter1 from '../assets/img_y/roadmap/Karekter-1.png'
import Karakter2 from '../assets/img_y/roadmap/Karekter-2.png'
import Karakter3 from '../assets/img_y/roadmap/Karekter-3.png'
import Karakter5 from '../assets/img_y/roadmap/Karekter-5.png'
import Axe from '../assets/img_y/roadmap/Axe.png'
import Kova from '../assets/img_y/roadmap/Kova-Light.png'
import SepetLight from '../assets/img_y/Sepet-Light.png'
import Dinamit from '../assets/img_y/roadmap/Dinamit.png'

function Roadmap() {
	return (
		<Fragment>
			<section id="roadmap-section" className="blog_section">
				<div className="container">
					<div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
						<h2 className="text_1 fs_95 shadow_1">Roadmap</h2>
					</div>
					<div className="row text-center wow fadeInUp align-items-center justify-content-center" data-wow-delay="0.5s" data-wow-duration="1.1s">
						<div className="col-md-3 col-sm-6">
							<div style={{ position: 'relative', marginTop: '30px' }}>
								<img src={gemi} alt="" style={{}} />
							</div>
							<div style={{ position: 'relative', top: '-140px' }}>
								<img src={stone_1} alt="" style={{ width: '100%' }} />
							</div>
							<div className="roadmap-list" style={{ background: 'url(' + parchment + ')', width: '300px', height: '342px', position: 'relative', top: '-240px', margin: '0 auto' }}>
								<ul>
									<li className="finished">
										<span>Oceanland v1 Mobile App Version Release</span>
									</li>
									<li className="finished">
										<span>Oceanland v1 Updates</span>
									</li>
									<li className="finished">
										<span>Oceanland v2 Development</span>
									</li>
									<li className="finished">
										<span>Oceanland Mini Game Series Development</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-3 col-sm-6">
							<div style={{ position: 'relative', marginBottom: '30px' }}>
								<img src={Karakter1} alt="" style={{}} />
							</div>
							<div style={{ position: 'relative', top: '-140px' }}>
								<img src={stone_2} alt="" style={{ width: '100%' }} />
							</div>
							<div className="roadmap-list" style={{ background: 'url(' + parchment + ')', width: '300px', height: '342px', position: 'relative', top: '-240px', margin: '0 auto' }}>
								<ul>
									<li className="waiting">
										<span>Treasure Hunt Early Access</span>
									</li>
									<li className="waiting">
										<span>Treasure Hunt Release</span>
									</li>
									<li className="waiting">
										<span>MGS Reward Track Program</span>
									</li>
									<li className="waiting">
										<span>Oceanland v2 Development Blog</span>
									</li>
									<li className="waiting">
										<span>Oceanland v2 Comic book Series release</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-3 col-sm-6">
							<div style={{ position: 'relative', marginBottom: '30px' }}>
								<img src={Karakter3} alt="" style={{}} />
							</div>
							<div style={{ position: 'relative', top: '-140px' }}>
								<img src={stone_3} alt="" style={{ width: '100%' }} />
							</div>
							<div className="roadmap-list" style={{ background: 'url(' + parchment + ')', width: '300px', height: '342px', position: 'relative', top: '-240px', margin: '0 auto' }}>
								<ul>
									<li className="waiting">
										<span>MSG Episode 2 Release</span>
									</li>
									<li className="waiting">
										<span>Oceanland v2 Development</span>
									</li>
									<li className="waiting">
										<span>Oceanland v2 Early Access</span>
									</li>
									<li className="waiting">
										<span>Oceanland v2 Play-to-Earn Release</span>
									</li>
									<li className="waiting">
										<span>Oceanland v2 Full Release</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row text-center wow fadeInUp align-items-center justify-content-center" data-wow-delay="0.5s" data-wow-duration="1.1s" style={{marginTop: '-140px'}}>
						<div className="col-md-3 col-sm-6">
							<div style={{ position: 'relative', marginBottom: '30px' }}>
								<img src={Karakter2} alt="" style={{}} />
							</div>
							<div style={{ position: 'relative', top: '-140px' }}>
								<img src={stone_4} alt="" style={{ width: '100%' }} />
							</div>
							<div className="roadmap-list" style={{ background: 'url(' + parchment + ')', width: '300px', height: '342px', position: 'relative', top: '-240px', margin: '0 auto' }}>
								<ul>
									<li className="waiting">
										<span>Oceanland Mini Game Series - Episode 3 Development and Release</span>
									</li>
									<li className="waiting">
										<span>Oceanland 2 Additions</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-3 col-sm-6">
							<div style={{ position: 'relative', marginBottom: '30px' }}>
								<img src={Karakter5} alt="" style={{}} />
							</div>
							<div style={{ position: 'relative', top: '-140px' }}>
								<img src={stone_5} alt="" style={{ width: '100%' }} />
							</div>
							<div className="roadmap-list" style={{ background: 'url(' + parchment + ')', width: '300px', height: '342px', position: 'relative', top: '-240px', margin: '0 auto' }}>
								<ul>
									<li className="waiting">
										<span>Oceanland Mini Game Series - Episode 4 Development and Release</span>
									</li>
									<li className="waiting">
										<span>Oceanland v2 Improvements and Additional Features</span>
									</li>
									<li className="waiting">
										<span>Locked & Unlocked Stake</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	)
}

export default Roadmap

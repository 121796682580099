import React from 'react'
import team_icon_1 from '../assets/img_y/team_icon_1.png'

function Team() {
    return (
        <section id="team-section" className="upcoming_gaming_section mb-222">
            <div className="container">
                <div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                    <h2 className="text_1 fs_95 shadow_1">Team</h2>
                </div>
                <div className="upcoming_gaming_inner ">
                    <div className="upcoming_gaming_list_2 wow fadeInUp text-center align-items-center mb-30" data-wow-delay="0.5s" data-wow-duration="1.1s">
                        <div className=" upcoming_gaming_list_3 bg-4 o-90">
                            <div className="row ">
                                <div className="col-lg-12 p-40 ">
                                    <div className="row fs_20">
                                        <div className="col-lg-12 text-left text_5 fs_30 font-5">
                                            <span className="yuzdeli_yazilar_1">
                                                <img src={team_icon_1} alt="" />
                                            </span>
                                            <a href="https://www.linkedin.com/in/eyup-akcetin-21249b141/">Dr. Eyup Akcetin, CEO</a>
                                            <br />
                                            <span className="yuzdeli_yazilar_1">
                                                <img src={team_icon_1} alt="" />
                                            </span>
                                            <a href="https://www.linkedin.com/in/%C3%BCmit-karaduman-2296aa62/">Umit Karaduman, CTO</a>
                                            <br />
                                            <span className="yuzdeli_yazilar_1">
                                                <img src={team_icon_1} alt="" />
                                            </span>
                                            <a href="#">Ismet Bahadir, Game Math </a>
                                            <br />
                                            <span className="yuzdeli_yazilar_1">
                                                <img src={team_icon_1} alt="" />
                                            </span>
                                            <a href="https://www.linkedin.com/in/erdem-atalayer-860544232/">Erdem Atalayer, Game Designer </a>
                                            <br />
                                            <span className="yuzdeli_yazilar_1">
                                                <img src={team_icon_1} alt="" />
                                            </span>
                                            <a href="https://www.linkedin.com/in/g%C3%B6rkem-atalayer-64546422a/">Görkem Atalayer, 2D Artist</a>
                                            <br />
                                            <span className="yuzdeli_yazilar_1">
                                                <img src={team_icon_1} alt="" />
                                            </span>
                                            <a href="https://www.linkedin.com/in/mehmet-ger%C3%A7ek-3b9374227/">Mehmet Gercek, 3D Modelling Artist </a>
                                            <br />
                                            <span className="yuzdeli_yazilar_1">
                                                <img src={team_icon_1} alt="" />
                                            </span>
                                            Tilmann Holm, Advisor
                                            <br />
                                            <span className="yuzdeli_yazilar_1">
                                                <img src={team_icon_1} alt="" />
                                            </span>
                                            Fabio Chiaramonte, Advisor
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team

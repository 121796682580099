import React from 'react'
import boat from '../assets/img_y/Gemi_Cerceve_Bg.png'
import island from '../assets/img_y/ada.png'
import Welcome from '../assets/img_y/Welcome.png'

function GamingVideo() {
    return (
        <div id="welcome-oceanland-section" className="gaming_video_section welcome_kismi">
            <section className="gaming_update_section mb-222">
                <div className="container">
                    <div className="section_title text-center wow fadeInUp mb-80" data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <img src={Welcome} alt="" style={{ width: '50%' }} />
                    </div>
                    <div className="gaming_video_thumb upcoming_gaming_list_2 o-90 bg-8 wow fadeInUp" style={{ backgroundImage: 'url(' + boat + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'top left' }} data-wow-delay="0.5s" data-wow-duration="1.1s" >
                        <div className="row">
                            <div className="col-12">
                                <div className="story_of_o"><img src={island} alt="" /></div>
                                <div className="text_5 fs_35 mt-40 lh-40 mb-40 ">Oceanland is a Play-to-Earn blockchain game that combines crypto economics with the gaming world. The players earn income by gathering resources required for the main character. Oceanland brings different aspects of blockchain technology together by merging several crypto assets with DeFi and NFT.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default GamingVideo

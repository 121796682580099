import React, { createContext, useState, useEffect } from 'react'

const LoadingContext = createContext()

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
    }, [loading])

    return <LoadingContext.Provider value={{ loading, setLoading }}>
        {children}
    </LoadingContext.Provider>
}

export default LoadingContext

import React from 'react'
import boat from '../assets/img_y/Gemi_Cerceve_Bg.png'
import fici from '../assets/img_y/K_fici.gif'

function GameStory() {
    return (
        <section id="game-story-section" className="gaming_update_section mb-222">
            <div className="container">
                <div className="section_title text-center wow fadeInUp mb-80" data-wow-delay="0.1s" data-wow-duration="1.1s">
                    <h2 className="text_1 fs_95 shadow_1">Game Story</h2>
                    <div className="text_1_1 mt-20 fs_50 shadow_4 renk-7">What Happened in Oceanland</div>
                </div>
                <div className="gaming_video_thumb upcoming_gaming_list_2 o-90 bg-8 wow fadeInUp" style={{ backgroundImage: 'url(' + boat + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'top left', marginTop: '-20px! important' }} data-wow-delay="0.5s" data-wow-duration="1.1s">
                    <div className="row">
                        <div className="col-12">
                            <div className="story_of_o">
                                <img src={fici} alt="" />
                            </div>
                            <div className=" text_5 fs_35 mt-40 lh-40">
                                The game story revolves around a man, who is the lone survivor of a shipwreck, washes ashore to an unknown mysterious island where he must gather necessary resources (food, water, wood and metal) to survive.
                                <br />
                                <span className="text-warning">Let's play, explore, solve mysteries and earn! </span>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GameStory

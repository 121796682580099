import React from 'react'
import { Link } from 'react-router-dom'
import arrowIcon from '../assets/img/icon/arrrow-icon.webp'
import Oceanland_Litepaper from '../assets/files/Oceanland_Whitepaper_v0822.pdf'
import { motion } from 'framer-motion'
import Video from '../components/video'

function HeroBannner({ loading, setLoading }) {
    return (
        <section id="hero-banner-section" className="hero_banner_section">
            <Video loading={loading} setLoading={setLoading} />
            <div className="container">
                <div className="row align-items-center22 hero_banner_inner22">
                    <div className="col-12">
                        <div className="hero_content2">
                            <h1 className="text_1 shadow_1">
                                <div className="row ani_1_mt wow fadeInUp mb-80" data-wow-delay="0.1s" data-wow-duration="1.1s">
                                    <div className="col-12" initial={{ x: -1500 }} animate={{ x: 0 }} transition={{ delay: 0.1, type: 'spring', stiffness: 120 }}>
                                        <div className="ani_1 font-1" data-wow-delay="1.1s" data-wow-duration="2.1s">Explore & Earn</div>
                                    </div>
                                    <div className="col-12" initial={{ x: -1500 }} animate={{ x: 0 }} transition={{ delay: 0.1, type: 'spring', stiffness: 120 }}>
                                        <div className="shadow_3 mb-20 ani_2 shadow_4 font-2" data-wow-delay="2.2s" data-wow-duration="2.2s" style={{ color: '#faba47', marginTop: '-95px !important' }}>
                                            SURVIVE AND EXPLORE THE ISLAND<br />REVEAL THE MYSTERIES
                                        </div>
                                    </div>
                                    <div initial={{ x: 1500 }} animate={{ x: -10 }} transition={{ delay: 0.5, type: 'spring', stiffness: 120 }}>
                                        <a className="btn btn-link fs_50" style={{ fontWeight: '100' }} href={Oceanland_Litepaper} target="_blank" rel="noreferrer">
                                            Whitepaper <img width="20" height="20" src={arrowIcon} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroBannner

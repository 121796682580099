import React, { Fragment, useEffect, useState } from 'react'
import video from '../assets/video/video_2.m4v'
import video_bg from '../assets/video/video-bg.png'

function Video({ loading, setLoading }) {
    return (
        <Fragment>
            <video autoPlay loop id="video-background" muted style={{ position: 'absolute' }} onLoadedData={() => setLoading(false)}>
                <source src={video} type="video/mp4" />
            </video>
        </Fragment>
    )
}

export default Video

import React from 'react'
import { Link } from 'react-router-dom'
import logo_alt from '../assets/img_y/logo-new.png'
import scroll_top from '../assets/img/icon/scroll-top.webp'
import { useScrollTo } from 'react-use-window-scroll'

function Footer() {
    const scrollTo = useScrollTo()

    return (
        <footer className="footer_widgets">
            <div className="main_footer footer_bottom_2 o-85">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-40 mb-40 text-center align-items-center">
                            <div className="footer_logo text-center">
                                <Link to="/">
                                    <img aria-label="logo" width="188" src={logo_alt} alt="" />
                                </Link>
                                <div>
                                    <span>
                                        <a aria-label="Medium" className="footer_s fs_medium me-2" href="https://oceanland.medium.com/" target="_blank" rel="noreferrer">
                                            <i className="fab fa-medium"></i>
                                        </a>
                                    </span>
                                    <span>
                                        <a aria-label="Twitter" className="footer_s fs_twitter me-2" href="https://twitter.com/oceanland_io" target="_blank" rel="noreferrer">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </span>
                                    <span>
                                        <a aria-label="Instagram" className="footer_s fs_instagram me-2" href="https://www.instagram.com/oceanland.io/" target="_blank" rel="noreferrer">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </span>
                                    <span>
                                        <a aria-label="Discord" className="footer_s fs_discord2 me-2" href="https://discord.gg/7vfWEzKQEW" target="_blank" rel="noreferrer">
                                            <i className="fab fa-discord"></i>
                                        </a>
                                    </span>
                                    <span style={{ display: 'none' }}>
                                        <a aria-label="Github" className="footer_s fs_medium me-2" href="https://github.com/oceanlandio" target="_blank" rel="noreferrer">
                                            <i className="fab fa-github"></i>
                                        </a>
                                    </span>
                                    <span>
                                        <a aria-label="Telegram" className="footer_s fs_telegram me-2" href="https://t.me/oceanland_io" target="_blank" rel="noreferrer">
                                            <i className="fab fa-telegram"></i>
                                        </a>
                                    </span>
                                    <span>
                                        <a aria-label="Telegram" className="footer_s fs_telegram2 me-2" href="https://t.me/Oceanland_en" target="_blank" rel="noreferrer">
                                            <i className="fab fa-telegram"></i>
                                        </a>
                                    </span>
                                    <span style={{ display: 'none' }}>
                                        <a aria-label="youtube" className="footer_s fs_youtube me-2" href="https://www.youtube.com" target="_blank" rel="noreferrer">
                                            <i className="fab fa-youtube"></i>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-40 mb-40 text-center align-items-center">
                            <h3 className="text_1 fs_50">Latest News</h3>
                            <Link className="twitter-timeline" data-width="420" data-height="200" data-theme="dark" to="https://twitter.com/oceanland_io?ref_src=twsrc%5Etfw"></Link>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-40 mb-40 text-center align-items-center">
                            <h3 className="text_1 fs_50">Subscribe to Our Newsletter</h3>
                            <div className="footer_menu">
                                <input type="text" name="" />
                                <input type="submit" name="" value="Submit" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="main_footer_inner d-flex">
                                <div className="footer_widget_list col-md-12"></div>
                                <div className="footer_widget_list footer_list_menu"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-7" style={{ height: '10px' }}></div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer_bottom_inner d-flex justify-content-between">
                                <div className="copyright_right fs_30">
                                    <p>
                                        <Link to="/">Oceanland</Link> © 2021-2022. All Rights Reserved.
                                    </p>
                                </div>
                                <div className="footer_bottom_link_menu">
                                    <ul className="d-flex">
                                        <li>
                                            <Link to="/">Terms & Condition </Link>
                                        </li>
                                        <li>
                                            <Link to="/">Privacy Policy </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="scroll__top_icon">
                                    <img aria-label="scroll-top" width="46" height="40" src={scroll_top} alt="" style={{ cursor: 'pointer' }} onClick={() => scrollTo({ top: 0, left: 0, behavior: "smooth" })} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer

import React, { Fragment, useState, useEffect, useRef } from 'react'
import logo from '../assets/img_y/logo-new.png'
import { Link } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'
import music from '../assets/music/music.mpeg'
import Oceanland_Litepaper from '../assets/files/Oceanland_Whitepaper_v0822.pdf'
import How_To_Create_BSC_Wallet from '../assets/files/how-to-create-bsc-wallet.pdf'
import Resources_And_Nfts from '../assets/files/resources-and-nft.pdf'
import { motion } from 'framer-motion'
import gemi from '../assets/img_y/Gemi_Cerceve_Bg_3.png'
import arrowIcon from "../assets/img/icon/arrrow-icon.webp";

const audio = new Audio(music)

function Header() {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [state, setState] = useState(false)

    useEffect(() => {
        const sticky = document.querySelector('.sticky-header')
        window.addEventListener('scroll', e => {
            if (window.pageYOffset < 100) {
                sticky.classList.remove('sticky')
            } else {
                sticky.classList.add('sticky')
            }
        })
    }, [])

    useEffect(() => {
        const body = document.querySelector('.body__bg')
        if (show) {
            body.style.paddingRight = ""
            body.style.position = "fixed"
        } else {
            body.style.position = ""
        }
    }, [show])

    const playPause = () => {
        if (state) {
            audio.pause()
            setState(false)
        } else {
            audio.play()
            setState(true)
        }
    }

    return (
        <Fragment>
            <header className="header_section header_transparent sticky-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="main_header fs_50 d-flex justify-content-between align-items-center">
                                <div className="header_logo">
                                    <a className="sticky_none" href="/">
                                        <img aria-label="logo" className="" width="215" height="79" src={logo} alt="" />
                                    </a>
                                </div>
                                <div className="main_menu d-none d-lg-block shadow_4 font-0">
                                    <nav>
                                        <ul className="d-flex">
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <Link to="#">Library</Link>
                                                <ul className="sub_menu">
                                                    <li>
                                                        <a href={How_To_Create_BSC_Wallet} target="_blank" rel="noreferrer">How to Create BSC Wallet</a>
                                                    </li>
                                                    <li>
                                                        <a href={Resources_And_Nfts} target="_blank" rel="noreferrer">Resources & NFTs</a>
                                                    </li>
                                                    <li>
                                                        <a href={Oceanland_Litepaper} target="_blank" rel="noreferrer">Whitepaper</a>
                                                    </li>
                                                    <li style={{ display: 'none' }}>
                                                        <Link to="/">Security Audits</Link>
                                                    </li>
                                                    <li style={{ display: 'none' }}>
                                                        <Link to="/">Statistics</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="https://defi.oceanland.io/" target="_blank" rel="noreferrer">
                                                    Market
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="#">Support</Link>
                                                <ul className="sub_menu">
                                                    <li>
                                                        <Link to="/FAQ">FAQ</Link>
                                                    </li>
                                                    <li>
                                                        <a href="https://t.me/oceanland_io" target="_blank" rel="noreferrer">Announcements</a>
                                                    </li>
                                                    <li style={{ display: 'none' }}>
                                                        <Link to="/">Contact and Communities</Link>
                                                    </li>
                                                    <li style={{ display: 'none' }}>
                                                        <Link to="/">Telegram Support</Link>
                                                    </li>
                                                    <li style={{ display: 'none' }}>
                                                        <Link to="/">Jobs</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div>
                                    <div className="d-none d-lg-block">
                                        <span>
                                            <a aria-label="Medium" className="footer_s fs_medium me-2" href="https://oceanland.medium.com/" target="_blank" rel="noreferrer">
                                                <i className="fab fa-medium"></i>
                                            </a>
                                        </span>
                                        <span>
                                            <a aria-label="Twitter" className="footer_s fs_twitter me-2" href="https://twitter.com/oceanland_io" target="_blank" rel="noreferrer">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </span>
                                        <span>
                                            <a aria-label="Discord" className="footer_s fs_discord2 me-2" href="https://discord.gg/7vfWEzKQEW" target="_blank" rel="noreferrer">
                                                <i className="fab fa-discord"></i>
                                            </a>
                                        </span>
                                        <span>
                                            <a aria-label="Telegram" className="footer_s fs_telegram me-2" href="https://t.me/oceanland_io" target="_blank" rel="noreferrer">
                                                <i className="fab fa-telegram"></i>
                                            </a>
                                        </span>
                                        <span>
                                            <a aria-label="Telegram" className="footer_s fs_telegram2 me-2" href="https://t.me/Oceanland_en" target="_blank" rel="noreferrer">
                                                <i className="fab fa-telegram"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div className="header_right_sidebar d-flex align-items-center">
                                    <div className="sing_up_btn d-none d-lg-block ">
                                        <a className="btn btn-link shadow_1" style={{ fontSize: '35px', fontWeight: '100' }} href="https://play.oceanland.io/" target="_blank" rel="noreferrer">
                                            Play & Earn
                                        </a>
                                        <span className="ms-2">
                                            <div aria-label="Music" className="footer_s fs_music me-2" to="#" onClick={playPause}>
                                                {state ? <i className="fas fa-volume-up"></i> : <i className="fas fa-volume-mute"></i>}
                                            </div>
                                        </span>
                                    </div>
                                    <div className="canvas_open">
                                        <button type="button" onClick={handleShow}>
                                            <i className="fas fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Offcanvas show={show} onHide={handleClose} style={{ backgroundImage: 'url(' + gemi + ')' }}>
                <Offcanvas.Header className="justify-content-end">
                    <button type="button" className="btn-close btn-close-white" onClick={handleClose}></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="offcanvas_main_menu font-0">
                        <li className="menu-item-has-children">
                            <div className="sing_up_btn">
                                <Link className="btn btn-link shadow_1" style={{ fontSize: '35px', fontWeight: '100' }} to="https://testnet.oceanland.io/">
                                    Play & Earn (Testnet)
                                </Link>
                            </div>
                        </li>
                        <li className="menu-item-has-children active">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="menu-item-has-children">
                            <Link to="#">Library</Link>
                            <ul className="sub_menu" style={{ marginLeft: '30px' }}>
                                <li>
                                    <a href={How_To_Create_BSC_Wallet} target="_blank" rel="noreferrer">How to Create BSC Wallet</a>
                                </li>
                                <li>
                                    <a href={Resources_And_Nfts} target="_blank" rel="noreferrer">Resources & NFTs</a>
                                </li>
                                <li>
                                    <a href={Oceanland_Litepaper} target="_blank" rel="noreferrer">Whitepaper</a>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/">Security Audits</Link>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/">Statistics</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <Link to="#">Support</Link>
                            <ul className="sub_menu" style={{ marginLeft: '30px' }}>
                                <li>
                                    <Link to="/FAQ">FAQ</Link>
                                </li>
                                <li>
                                    <a href="https://t.me/oceanland_io" target="_blank" rel="noreferrer">Announcements</a>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/">Contact and Communities</Link>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/">Telegram Support</Link>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/">Jobs</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <Link to="/">FAQ</Link>
                        </li>
                        <li className="menu-item-has-children ">
                            <div className=" a_link_beyaz ">
                                <span>
                                    <a aria-label="Medium" className="footer_s fs_medium me-2" href="https://oceanland.medium.com/" target="_blank" rel="noreferrer">
                                        <i className="fab fa-medium"></i>
                                    </a>
                                </span>
                                <span>
                                    <a aria-label="Twitter" className="footer_s fs_twitter me-2" href="https://twitter.com/oceanland_io" target="_blank" rel="noreferrer">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </span>
                                <span>
                                    <a aria-label="Discord" className="footer_s fs_discord2 me-2" href="https://discord.gg/7vfWEzKQEW" target="_blank" rel="noreferrer">
                                        <i className="fab fa-discord"></i>
                                    </a>
                                </span>
                                <span>
                                    <a aria-label="Telegram" className="footer_s fs_telegram me-2" href="https://t.me/oceanland_io" target="_blank" rel="noreferrer">
                                        <i className="fab fa-telegram"></i>
                                    </a>
                                </span>
                            </div>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    )
}

export default Header

import React from 'react'
import token_01 from '../assets/img_y/token_01.png'
import token_02 from '../assets/img_y/token_02.png'
import token_03 from '../assets/img_y/token_03.png'
import token_04 from '../assets/img_y/token_04.png'
import token_05 from '../assets/img_y/token_05.png'
import token_06 from '../assets/img_y/token_06.png'
import token_07 from '../assets/img_y/token_07.png'
import token_08 from '../assets/img_y/token_08.png'
import token_09 from '../assets/img_y/token_09.png'
import token_10 from '../assets/img_y/token_10.png'
import token_11 from '../assets/img_y/token_11.png'
import token_12 from '../assets/img_y/token_12.png'
import token_13 from '../assets/img_y/token_13.png'
import token_14 from '../assets/img_y/token_14.png'
import token_15 from '../assets/img_y/token_15.png'
import token_16 from '../assets/img_y/token_16.png'
import token_17 from '../assets/img_y/tfbx_token.png'
import hazine from '../assets/img_y/hazine-v2.png'

function TreasureTokens() {
    return (
        <section id="treasure-tokens-section" className="upcoming_gaming_section mb-222">
            <div className="container">
                <div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                    <h2 className="text_1 fs_95 shadow_1">Treasure Tokens </h2>
                </div>
                <div className="text_1_1 mt-40 fs_50 shadow_4 ln45 text-center renk-7">You can stake Treasure Tokens into Oceanland ecosystem in order to earn greater rewards. Supported tokens are listed below.</div>
                <div className="gaming_video_thumb mt-100 upcoming_gaming_list_2 o-90 text-center bg-8 wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1.1s">
                    <div className="row" style={{ backgroundImage: 'url(' + hazine + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', backgroundSize: '18%' }}>
                    <div className="col-md-2 col-sm-3 col-4 mb-25">
                    <div className="upcoming_gaming_list_32 bg-82">
                        <img src={token_17} alt="" />
                    </div>
                </div>
                    <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_13} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_01} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_02} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_03} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_04} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_05} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_06} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_07} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_08} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_09} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_10} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_11} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_12} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_14} alt="" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_15} alt="" />
                            </div>
                        </div>   
                        <div className="col-md-2 col-sm-3 col-4 mb-25">
                            <div className="upcoming_gaming_list_32 bg-82">
                                <img src={token_16} alt="" />
                            </div>
                        </div>                                                
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TreasureTokens

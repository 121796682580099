import React from 'react'
import token_dist_1 from '../assets/img_y/token_dist_1.png'
import logo512 from '../assets/img_y/logo192.png'

function TokenAllication() {
	return (
		<section id="token-allocation-section" className="upcoming_gaming_section mb-222">
			<div className="container">
				<div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
					<h2 className="text_1 fs_95 shadow_1">Token Allocation</h2>
					<div className="text_1_1 mt-40 fs_50 shadow_4 ln45  shadow_1 renk-7">
						OLAND token is the native token of Oceanland and is used for in-game swapping, staking and farming. There will be a total of 960.000.000 OLAND,
						<br />
						allocated as follows:
					</div>
				</div>
				<div className="upcoming_gaming_inner ">
					<div className="upcoming_gaming_list_2 wow fadeInUp  text-center  align-items-center mb-30" data-wow-delay="0.5s" data-wow-duration="1.1s">
						<div className=" upcoming_gaming_list_3  bg-4 o-90">
							<div className="row ">
								<div className="col-lg-5  p-40 bg-8 upcoming_gaming_list_3">
									<div className="row fs_20 ">
										<div className="col-lg-12 text-center text_5 fs_30" style={{ marginLeft: '-20px' }}>
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												Seed
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%5
											</span>
											<br />
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												IGO
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%2
											</span>
											<br />
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												Liquidity
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%8.5
											</span>
											<br />
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												Play to Earn
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%32.5
											</span>
											<br />
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												Team
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%10
											</span>
											<br />
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												Development
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%10
											</span>
											<br />
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												Marketing
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%15
											</span>
											<br />
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												Strategic Partners
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%15
											</span>
											<br />
											<span className="yuzdeli_yazilar_3" style={{ width: '61%', textAlign: 'left' }}>
												Advisors
											</span>
											<span className="yuzdeli_yazilar_1">
												<b style={{ color: '#fff', paddingRight: '10px' }}>:</b>%2
											</span>
											<br />
										</div>
									</div>
								</div>
								<div className="col-lg-6 p-20" style={{ margin: '0 auto', position: 'relative' }}>
                                    <img src={token_dist_1} alt="" style={{margin: 'auto'}} />
                                </div>
							</div>
						</div>
					</div>
				</div>
				<div className="upcoming_gaming_inner text-center">
					<div className="row">
						<div className="col-lg-12 text-center ">
							<img src={logo512} alt="" style={{ width: '125px' }} />
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 text_1_1 mt-40 fs_50 shadow_4 ln45  shadow_1 renk-7">
							<span>OLAND BSC Contract Address : </span>
							<br />
							0xB0461d7E8212D311b842A58e9989edE849ac6816
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default TokenAllication

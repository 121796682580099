import React, { Fragment } from 'react'
import boat from '../assets/img_y/Gemi_Cerceve_Bg_2.png'
import Crt1 from '../assets/img_y/Crt1.png'
import Crt2 from '../assets/img_y/Crt2.png'
import Crt3 from '../assets/img_y/Crt3.png'
import Crt4 from '../assets/img_y/Crt4.png'

function HowToObtainNfts() {
    return (
        <Fragment>
            <section id="how-to-obtain-nfts-1-section" className="blog_section mb-222">
                <div className="container">
                    <div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <h2 className="text_1 fs_95 shadow_1">How to Obtain NFTs</h2>
                        <div className="text_1_1 mt-40 fs_50 shadow_4 ln45 renk-7">
                            NFTs can be obtained through crafting, trading and staking. You can also upgrade your crafted lowest level NFTs. NFT minting by swapping, staking, and farming several other tokens will also be available.
                        </div>
                    </div>
                    <div className="row gaming_video_thumb upcoming_gaming_list_2 o-90 wow fadeInUp" style={{ backgroundImage: 'url(' + boat + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'top left', paddingTop: '40px', paddingBottom: '20px' }} data-wow-delay="0.5s" data-wow-duration="1.1s">
                        <div className="col-md-4 col-sm-6 text-center"><img src={Crt1} style={{ padding: 0 }} alt="" /></div>
                        <div className="col-md-4 col-sm-6 text-center"><img src={Crt2} style={{ padding: 0 }} alt="" /></div>
                        <div className="col-md-4 col-sm-6 text-center"><img src={Crt3} style={{ padding: 0 }} alt="" /></div>
                    </div>
                </div>
            </section>
            <section id="how-to-obtain-nfts-2-section" className="blog_section mb-222">
                <div className="container">
                    <div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <h2 className="text_1 fs_95 shadow_1">UPGRADING NFTS</h2>
                        <div className="text_1_1 mt-40 fs_50 shadow_4 ln45 renk-7">
                            The process of converting NFTs to a more productive version is called Upgrading. In order for the players to upgrade an NFT Tool, they must have the Ticket of the higher-level NFT Tool, the NFT Tool to be upgraded, and the necessary resources.
                        </div>
                    </div>
                    <div className="row gaming_video_thumb upcoming_gaming_list_2 o-90 wow fadeInUp mt-5" style={{ backgroundImage: 'url(' + boat + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'top left', paddingTop: '40px', paddingBottom: '20px' }} data-wow-delay="0.5s" data-wow-duration="1.1s">
                        <div className="col-md-12 col-sm-6 text-center"><img src={Crt4} style={{ padding: 0, width: '75%' }} alt="" /></div>
                    </div>
                </div>
            </section>


        </Fragment>

    )
}

export default HowToObtainNfts
import React, { Fragment } from 'react'
import ref1 from '../assets/img_y/Water.png'
import ref2 from '../assets/img_y/Food.png'
import ref3 from '../assets/img_y/Wood.png'
import ref4 from '../assets/img_y/Metal.png'
import ferman_1 from '../assets/img_y/ferman_1.png'

function Resources() {
    return (
        <Fragment>
            <section id="resources-1-section" className="gaming_video_section mb-222">
                <div className="container">
                    <div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <h2 className="text_1 fs_95 shadow_1">Resources</h2>
                        <div className="text_1_1 mt-40 fs_50 shadow_4 ln45 renk-7">
                            There are four types of resources in Oceanland. <br />
                            Players can use these resources to craft NFT tools in the game.
                        </div>
                    </div>
                    <div className="row wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1.1s">
                        <div className="col-md-3 col-sm-6 col-6">
                            <img src={ref1} alt="" />
                        </div>
                        <div className="col-md-3 col-sm-6 col-6">
                            <img src={ref2} alt="" />
                        </div>
                        <div className="col-md-3 col-sm-6 col-6">
                            <img src={ref3} alt="" />
                        </div>
                        <div className="col-md-3 col-sm-6 col-6">
                            <img src={ref4} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="resources-2-section" className="upcoming_gaming_section mb-222" style={{ display: 'none' }}>
                <div className="container">
                    <div className="upcoming_gaming_inner">
                        <div className="upcoming_gaming_list_2 wow fadeInUp d-flex justify-content-between align-items-center mb-30" data-wow-delay="0.1s" data-wow-duration="1.1s">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single_blog2 d-flex align-items-center wow fadeInUp upcoming_gaming_list_3 bg-8 pt-40 pb-40 o-90" data-wow-delay="0.5s" data-wow-duration="1.1s">
                                        <div className="blog_thumb2 ferman">
                                            <img src={ferman_1} alt="" />
                                        </div>
                                        <div className="blog_content">
                                            <div className="text_5 fs_35 lh-40">As our hero explores the island, he encounters certain symbols and objects that hints that the island may have been previously inhabited. These symbols will unlock the mystery of the island and help our hero accelerate his journey.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Resources

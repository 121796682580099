import React, { Fragment, useState, useEffect, useCallback } from 'react'
import ReactPageScroller from 'react-page-scroller'
import Header from '../components/header'
import HeroBanner from '../sections/hero-banner'
import WelcomeOceanland from '../sections/welcome-oceanland'
import NftTools from '../sections/nft-tools'
import GameStory from '../sections/game-story'
import Resources from '../sections/resources'
import Roadmap from '../sections/roadmap'
import HowToObtainNfts from '../sections/how-to-obtain-nfts'
import Partners from '../sections/partners'
import TreasureTokens from '../sections/treasure-tokens'
import TokenAllocation from '../sections/token-allocation'
import ListedExchanges  from '../sections/listed-exchanges'
import Team from '../sections/team'
import Footer from '../components/footer'

export default function Home({ loading, setLoading }) {
    const [currentPage, setCurrentPage] = useState(0)

    const [showPlayer, setShowPlayer] = useState(true)

    const handlePageChange = useCallback(
        number => {
            setCurrentPage(number)
        },
        [setCurrentPage]
    )

    const handleBeforePageChange = number => setShowPlayer(false)

    useEffect(() => {
        document.body.classList.remove(...document.body.classList)
        document.body.classList.add('body__bg')
        document.body.classList.add('home-page')
    }, [])

    return (
        <Fragment>
            {!loading && <Fragment>
                <HeroBanner loading={loading} setLoading={setLoading} />
                <WelcomeOceanland />
                <GameStory />
                <Resources />
                <NftTools />
                <HowToObtainNfts />
                <Roadmap />
                <Partners />
                <TreasureTokens />
                <TokenAllocation />
                <ListedExchanges />
                <Team />
            </Fragment>}
        </Fragment>
    )
}
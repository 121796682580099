import { useEffect, useState } from 'react'
import WOW from 'wowjs'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/header'
import Footer from './components/footer'
import Home from './containers/home'
import FAQ from './containers/faq'
import PlayAndEarn from './containers/play-and-earn'
import useLoading from './hooks/loading'

function App() {
  const { loading, setLoading } = useLoading()

  useEffect(() => {
    new WOW.WOW({ live: false }).init()
  }, [])

  return (
    <Router>
      {loading ? <div id="loader-wrapper">
        <div id="loader"></div>
        <div className="loader-section"></div>
      </div> : <Header />}
      <div className="page_wrapper">
        <Routes>
          <Route path="/" element={<Home loading={loading} setLoading={setLoading} />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/PlayAndEarn" element={<PlayAndEarn />} />
        </Routes>
      </div>
      {!loading && <Footer />}
    </Router>
  )
}

export default App
import React from 'react'
import exchange_1 from '../assets/img_y/exchanges/exchange_1.png'
import exchange_2 from '../assets/img_y/exchanges/exchange_2.png'
import exchange_3 from '../assets/img_y/exchanges/exchange_3.png'

function ListedExchanges() {
    return (
        <section id="partners-section" className="upcoming_gaming_section mb-222">
            <div className="container">
                <div className="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                    <h2 className="text_1 fs_95 shadow_1">Listed Exchanges</h2>
                </div>
                <div className="row wow fadeInUp d-flex justify-content-center" data-wow-delay="0.5s" data-wow-duration="1.1s">
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-white" style={{ background: '#3d7dca !important', backgroundColor: '#3d7dca !important' }}>
                            <img src={exchange_1} alt="" />
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                            <a class="btn btn-link shadow_1" href="https://www.huobi.com/en-us/exchange/oland_usdt" target="_blank" rel="noreferrer" style={{ width: '157px', height: '60px', lineHeight: '60px' }}>BUY</a>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-white" style={{ background: '#3d7dca !important', backgroundColor: '#3d7dca !important' }}>
                            <img src={exchange_2} alt="" />
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                            <a class="btn btn-link shadow_1" href="https://www.gate.io/trade/OLAND_USDT" target="_blank" rel="noreferrer" style={{ width: '157px', height: '60px', lineHeight: '60px' }}>BUY</a>
                        </div>                        
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-25">
                        <div className="upcoming_gaming_list_3 text-center bg-white" style={{ background: '#3d7dca !important', backgroundColor: '#3d7dca !important' }}>
                            <img src={exchange_3} alt="" />
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                            <a class="btn btn-link shadow_1" href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xB0461d7E8212D311b842A58e9989edE849ac6816" target="_blank" rel="noreferrer" style={{ width: '157px', height: '60px', lineHeight: '60px' }}>BUY</a>
                        </div>                        
                    </div>                                                         
                </div>
            </div>
        </section>
    )
}

export default ListedExchanges

import React from 'react'
import Slider from 'react-slick'
import arrow1 from '../assets/img/icon/navigation-arrow1.webp'
import arrow2 from '../assets/img/icon/navigation-arrow2.webp'
import nft1 from '../assets/img_y/nft1.png'
import nft2 from '../assets/img_y/nft2.png'
import nft3 from '../assets/img_y/nft3.png'
import nft4 from '../assets/img_y/nft4.png'
import nft5 from '../assets/img_y/nft5.png'
import nft6 from '../assets/img_y/nft6.png'
import nft7 from '../assets/img_y/nft7.png'
import nft8 from '../assets/img_y/nft8.png'
import nft9 from '../assets/img_y/nft9.png'
import nft10 from '../assets/img_y/nft10.png'
import nft11 from '../assets/img_y/nft11.png'
import nft12 from '../assets/img_y/nft12.png'
import nft13 from '../assets/img_y/nft13.png'
import nft14 from '../assets/img_y/nft14.png'
import nft15 from '../assets/img_y/nft15.png'
import nft16 from '../assets/img_y/nft16.png'

const PrevArrow = props => {
    const { onClick } = props
    return (
        <button className="prev_arrow" onClick={onClick} style={{ display: 'block' }}>
            <img width="17" height="24" src={arrow2} alt="" />
        </button>
    )
}

const NextArrow = props => {
    const { onClick } = props
    return (
        <button className="next_arrow" onClick={onClick} style={{ display: 'block' }}>
            <img width="17" height="24" src={arrow1} alt="" />
        </button>
    )
}

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
        { breakpoint: 700, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 1080, settings: { slidesToShow: 2, slidesToScroll: 2 } },
        { breakpoint: 1400, settings: { slidesToShow: 3, slidesToScroll: 3 } }
    ]
}

function NftTools() {
    return (
        <section id="nft-tools-section" className="gaming_video_section mb-105">
            <div className="section_title text-center wow fadeInUp mb-601" data-wow-delay="0.1s" data-wow-duration="1.1s">
                <h2 className="text_1 fs_95 shadow_1">NFT TOOLS</h2>
                <div className="container">
                    <div className="text_1_1 fs_50 mt-40  shadow_4 renk-7 ln45">
                        NFT cards are used to obtain resources.
                        <br />
                        NFTs can be owned in three ways: Crafting, Trading, and Staking
                    </div>
                </div>
            </div>
            <Slider {...settings} className="regular slider gaming_video_inner slick_navigation wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1.1s">
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft1 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world22 wow fadeInUp" style={{ backgroundImage: 'url(' + nft2 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world22 wow fadeInUp" style={{ backgroundImage: 'url(' + nft3 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world22 wow fadeInUp" style={{ backgroundImage: 'url(' + nft4 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world22 wow fadeInUp" style={{ backgroundImage: 'url(' + nft5 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft7 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world22 wow fadeInUp" style={{ backgroundImage: 'url(' + nft6 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft8 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft10 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft9 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft11 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft12 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft13 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft14 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft15 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
                <div>
                    <div className="single_gaming_world wow fadeInUp" style={{ backgroundImage: 'url(' + nft16 + ')' }} data-wow-delay="0.1s" data-wow-duration="1.1s" />
                </div>
            </Slider>
        </section>
    )
}

export default NftTools

import React, { useEffect } from 'react'
import Faq from 'react-faq-component'

const styles = {
    bgColor: 'transparent',
    titleTextColor: 'white',
    rowTitleColor: 'white',
    rowContentColor: 'white',
    arrowColor: "white",
}

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
}

const data = {
    rows: [
        {
            title: `What is OCEANLAND?`,
            content: `OCEANLAND is a play-to-earn survival game built on the blockchain platform. Collect resources, build tools, trade goods with other players and explore this adventurous island.`
        },
        {
            title: `What blockchain platform does OCEANLAND run on?`,
            content: `OCEANLAND is an NFT game that runs on the Binance Smart Chain (BSC) blockchain network.`
        },
        {
            title: `How can I take part in OCEANLAND?`,
            content: `You are required to have a BSC wallet and purchase NFT tools to play OCEANLAND.`
        },
        {
            title: `What’s the difference between OCEANLAND than other play-to-earn games?`,
            content: `<ul><li>You’ll have daily missions from which you’ll gather parts of symbols. You’ll be rewarded when a mystery symbol is revealed completely. Symbols are relics of an ancient civilization that inhabited the island long long ago. Collect parts of these symbols on daily missions, use these symbols to solve the mystery and earn your reward.</li><li>You can earn special NFTs by staking treasure tokens.</li><li>A decentralized exchange will be provided to the gamers in order to swap in-game goods.</li></ul>`
        },
        {
            title: `What is Treasure Token?`,
            content: `Treasure tokens are tokens that you can stake into our system in order to earn special NFTs. See our web page for the list of supported tokens.`
        },
        {
            title: `How can I play the game?`,
            content: `You need to create a BSC wallet and purchase NFTs from the market in order to produce basic survival materials.`
        },
        {
            title: `What is OLAND token?`,
            content: `OLAND is the currency used in this game. It can be traded in crypto exchanges.`
        },
        {
            title: `How can I earn OLAND in the game?`,
            content: `You can gather branches, leaves or wood from the forest, collect fruits, hunt wild animals, go fishing, collect and store water and mine by using the NFT tools you have. Then, you can sell these goods in the market and convert them into OLAND token.`
        },
        {
            title: `How can I obtain tools in OCEANLAND?`,
            content: `You have several ways of acquiring new tools with a new character: <ul><li>Airdrop</li><li>Packet sales Trading</li><li>Crafting</li><li>Staking</li><li>In game gifts</li></ul>`
        },
        {
            title: `What does countdown time mean?`,
            content: `Indicator of the time required for the recharge of the item before it can mine again. You cannot remove or change the item until countdown time is completed.`
        }
    ]
}

export default function FAQ() {
    useEffect(() => {
        document.body.classList.remove(...document.body.classList)
        document.body.classList.add('body__bg')
        document.body.classList.add('faq-page')
    }, [])

    return (
        <div className="gaming_video_section welcome_kismi">
            <section className="gaming_update_section mb-222">
                <div className="container">
                    <div className="section_title text-center wow fadeInUp mb-40" style={{ marginTop: '150px' }} data-wow-delay="0.1s" data-wow-duration="1.1s">
                        <h2 className="text_1 fs_80 shadow_1"><span style={{ fontSize: '60px', color: '#fff' }}>Do You Have Questions?</span></h2>
                        <h3 className="text_1_1 mt-20 fs_50 shadow_4 renk-7"><span style={{ textTransform: 'none' }}>We have answers (Well, most of the times<b style={{ fontFamily: 'sans-serif' }}>!</b>)</span></h3>
                    </div>
                    <div className="gaming_video_thumb upcoming_gaming_list_2 o-90 bg-8 wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1.1s">
                        <div className="row">
                            <div className="col-12">
                                <Faq className="faq-list" data={data} styles={styles} config={config} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}